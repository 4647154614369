<template>
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M20.3438 18.3203L15.6172 13.5938C15.5 13.5156 15.3828 13.4375 15.2656 13.4375H14.7578C15.9688 12.0312 16.75 10.1562 16.75 8.125C16.75 3.67188 13.0781 0 8.625 0C4.13281 0 0.5 3.67188 0.5 8.125C0.5 12.6172 4.13281 16.25 8.625 16.25C10.6562 16.25 12.4922 15.5078 13.9375 14.2969V14.8047C13.9375 14.9219 13.9766 15.0391 14.0547 15.1562L18.7812 19.8828C18.9766 20.0781 19.2891 20.0781 19.4453 19.8828L20.3438 18.9844C20.5391 18.8281 20.5391 18.5156 20.3438 18.3203ZM8.625 14.375C5.14844 14.375 2.375 11.6016 2.375 8.125C2.375 4.6875 5.14844 1.875 8.625 1.875C12.0625 1.875 14.875 4.6875 14.875 8.125C14.875 11.6016 12.0625 14.375 8.625 14.375Z" />
  </svg>
</template>
